<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.footer_box {
  width: 100%;
  height: 300px;
  background-color: #333333;
  color: #b8b8b8;
  .w {
    width: 1200px;
    margin: 0 auto;
  }
}
.footerT {
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  justify-content: space-between;
}
.footer_list {
  width: 750px;
  justify-content: space-between;
  .item {
    text-align: center;
    a {
      display: block;
      cursor: pointer;
      height: 30px;
      line-height: 30px;
    }
    .one {
      font-size: 18px;
      height: 45px;
      line-height: 45px;
      color: #cccccc;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    .two {
      height: 30px;
      line-height: 30px;
    }
    .two:hover {
      color: #fff;
      cursor: pointer;
    }
  }
}
.right {
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  .image {
    margin-bottom: 10px;
    width: 120px;
    height: 120px;
  }
}
.footerB {
  margin-top: 10px;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
}
</style>
<template>
  <div class="footer_box">
    <div class="m_20"></div>
    <div class="footer w">
      <div class="footerT flex">
        <div class="footer_list flex">
          <div class="item">
            <div class="one">新手指南</div>
            <!-- <a
              target="_blank"
              href="https://www.showdoc.com.cn/1133719757897934?page_id=5775255463716102"
              class="two"
              >如何注册</a
            >
            <a
              target="_blank"
              href="https://www.showdoc.com.cn/1133719757897934?page_id=5775255838439976"
              class="two"
              >如何登录</a
            > -->
            <div class="two" @click="goNext(0)">如何注册</div>
            <div class="two" @click="goNext(1)">如何登录</div>
          </div>
          <div class="item">
            <div class="one">支付方式</div>
            <!-- <a
              target="_blank"
              href="https://www.showdoc.com.cn/1133719757897934?page_id=5775261594361615"
              class="two"
              >在线支付</a
            > -->
            <div class="two" @click="goNext(2)">在线支付</div>
          </div>
          <div class="item">
            <div class="one">产品服务</div>
            <!-- <a
              target="_blank"
              href="https://www.showdoc.com.cn/1133719757897934?page_id=5775264540331439"
              class="two"
              >服务条款</a
            >
            <a
              target="_blank"
              href="https://www.showdoc.com.cn/1133719757897934?page_id=5775267484062650"
              class="two"
              >权限说明</a
            >
            <a
              target="_blank"
              href="https://www.showdoc.com.cn/1133719757897934?page_id=5775268661942740"
              class="two"
              >用户协议</a
            > -->
            <div class="two" @click="goNext(3)">服务条款</div>
            <div class="two" @click="goNext(5)">权限说明</div>
            <div class="two" @click="goNext(4)">用户协议</div>
          </div>
          <div class="item">
            <div class="one">帮助说明</div>
            <!-- <a target="_blank" href="http://app.hnzye.com/" class="two"
              >App下载</a
            > -->
            <div class="two" @click="goDownload">App下载</div>
          </div>
        </div>
        <div class="right">
          <div class="image">
            <img :src="footerInfo.android" alt />
          </div>
          <div>安卓APP</div>
        </div>
      </div>
      <div class="footerB">
        <div>{{ footerInfo.title }}在线学习平台 版权所有</div>
        <div>
          <a style="cursor: pointer" :href="footerInfo.business" target="_blank"
            >营业执照</a
          >
          网络文化许可证：{{ footerInfo.wlwh }} 增值电信业务许可证：{{
            footerInfo.increment
          }}
        </div>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          >{{ footerInfo.icp }}</a
        >
        <a href="http://www.chaicp.com/wangan.html" target="_blank">{{
          footerInfo.security
        }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import { footer } from "../../assets/js/index";
export default {
  data() {
    return {
      footerInfo: {},
    };
  },
  mounted() {
    this.getFooter();
  },
  methods: {
    goNext(i) {
      const { href } = this.$router.resolve({
        path: "/footerLink",
        query: {
          active: i,
        },
      });
      window.open(href, "_blank");
      // this.$router.push("/footerLink");
    },
    goDownload() {
      const { href } = this.$router.resolve({
        path: "/download",
      });
      window.open(href, "_blank");
    },
    getFooter() {
      footer({
        siteId: window.localStorage.getItem("siteId"),
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.footerInfo = res.data;
        }
      });
    },
  },
};
</script>